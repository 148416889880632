import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import BlockContainer from '../BlockContainer';
import { ListingCard } from '../../../../components';
import { propTypes } from '../../../../util/types';

import css from './BlockListing.module.css';

const BlockListing = props => {
  const { blockId, className, rootClassName, listing } = props;
  const classes = classNames(rootClassName || css.root, className);
  const intl = useIntl();

  return (
    <BlockContainer id={blockId} className={classes}>
      <ListingCard intl={intl} listing={listing} showAuthorInfo={false}/>
    </BlockContainer>
  );
};

BlockListing.defaultProps = {
  className: null,
  rootClassName: null,
};

BlockListing.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  listing: propTypes.listing.isRequired,
};

export default BlockListing;
